/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-08",
    versionChannel: "nightly",
    buildDate: "2023-09-08T00:19:55.223Z",
    commitHash: "a3e0a84a3d3b8618c45cf8b652e3340328dc5a12",
};
